import React, { useState } from "react";
import EnhancedLearning from "./Component/EnhancedLearning";
import Unleash from "./Component/Unleash";
import Banner from "@/components/LandingPage/Common/Banner";
import Worldwide from "./Component/WorldWide";
import Learning from "@/components/LandingPage/Common/Learning";
import LokiTabs from "@/components/LandingPage/Common/LokiTabs";
import CollaborativeDebates from "./Component/CollaborativeDebates";
import { useTranslation } from "react-i18next";
import Modal from "@/components/Modal";
import TextToSpeechModal from "@/components/Modal/TextToSpeechModal";
import CollaborativeDebateModal from "@/components/Modal/CollaborativeDebateModal";
import ProPlan from "./Component/ProPlan";
import EmpowerProductivity from "./Component/EmpowerProductivity";
import TextToImageModal from "@/components/Modal/TextToImageModal";
import FreeTrialModal from "@/components/Modal/FreeTrialModal";
import DiscoverModal from "@/components/Modal/DiscoverModal";
import { useColorMode } from "@chakra-ui/color-mode";
import Head from "next/head";
import { Illustration } from "modules/FeaturesModule/Component/Illustration";
import Questions from "modules/FeaturesModule/Component/Questions";
import FeatureData from "@/mocks/guestFeature";
import UseCase from "./Component/UseCase";
import WhyLoki from "./Component/WhyLoki";
import VideoPlayer from "modules/FeaturesModule/Component/VideoPlayer";
import Select from "@/components/Select";
const HomeModule = () => {
  const { t } = useTranslation("translation");
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleCollaborate, setVisibleCollaborate] = useState<boolean>(false);
  const [visibleTextToImage, setVisibleTextToImage] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";
  const { mainquestionAnswer } = FeatureData();

  const schemaBreadcrumb = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Loki: Advanced AI Tools for Productivity",
        item: "https://lokichat.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "AI Tools",
        item: "https://lokichat.com/features",
      },
      // {
      //   "@type": "ListItem",
      //   position: 3,
      //   name: "How it Works",
      //   item: "https://lokichat.com/how-it-works",
      // },
      {
        "@type": "ListItem",
        position: 4,
        name: "App Download",
        item: "https://lokichat.com/download",
      },
      {
        "@type": "ListItem",
        position: 5,
        name: "Pricing",
        item: "https://lokichat.com/pricing-home",
      },
    ],
  };

  const schemaProduct = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: "Loki: AI Tools",
    url: "https://lokichat.com/",
    image: "https://lokichatdev.blob.core.windows.net/images/loki/logo.svg",
    description:
      "Enhance productivity with Loki AI tools like Debate, Prompt Advisor, Copywriter, Translation, Transcription, Summarization, Text-to-Speech, Text-to-Image, and Goal Management.",
    brand: {
      "@type": "Brand",
      name: "Loki",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.9",
      reviewCount: "200",
    },
    offers: {
      "@type": "Offer",
      priceCurrency: "EUR",
      price: "10",
      priceValidUntil: "2025-12-31",
      itemCondition: "New",
      availability: "InStock",
      hasMerchantReturnPolicy: {
        "@type": "MerchantReturnPolicy",
        refundType: "NoRefunds",
        returnPolicyCategory: "Unopened",
        restockingFee: "NoRestockingFee",
      },
      shippingDetails: {
        "@type": "OfferShippingDetails",
        shippingRate: {
          "@type": "MonetaryAmount",
          currency: "EUR",
          value: "0.00",
        },
        shippingDestination: {
          "@type": "DefinedRegion",
          addressRegion: "Worldwide",
        },
        estimatedDeliveryTime: {
          "@type": "QuantitativeValue",
          value: "Instant",
          unitText: "Delivery",
        },
      },
    },
  };

  const schemaRating = {
    "@context": "https://lokichat.com/",
    "@type": "Product",
    name: "Loki: Best Productivity AI Tools Online",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      ratingCount: "45",
      reviewCount: "52",
    },
  };

  return (
    <div>
      {/* <div className="max-w-[800px] m-auto -mt-3">
        <div className=" max-w-[250px]">
          <Select
            classButton="shadow-none bg-transparent rounded-t-lg rounded-b-none ui-open:shadow-none dark:bg-n-5 dark:shadow-none dark:text-n-6"
            title={"Select Feature"}
            items={widgets}
            value={widget}
            fromHome={true}
            onChange={setWidget}
            // PreIcon={() => <VoiceIcon />}
            arrowColor={"black"}
          />
        </div>
      </div> */}
      <Head>
        <title>Welcome to Loki Chat</title>
        <meta
          name="description"
          content="Loki: Best Productivity AI Tools Online"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaBreadcrumb) }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaProduct) }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaRating) }}
        />
      </Head>
      <Banner />
      <ProPlan />
      {/* <Learning
        title={t("title_learn2")}
        img="https://lokichatdev.blob.core.windows.net/devloki/loki-images/text-to-speech.webp"
        description={t("title_2desc")}
        setVisibleModal={setVisibleModal}
      /> */}
      {/* <CollaborativeDebates
        title={t("debate_groupStudy")}
        img="https://lokichatdev.blob.core.windows.net/devloki/pricing-page/collaborate.webp"
        description={t("debate_desc")}
        setVisibleCollaborate={setVisibleCollaborate}
      /> */}
      {/* <Learning
        title={t("title_learn6")}
        img="https://lokichatdev.blob.core.windows.net/devloki/loki-home/Text-image-generation.webp"
        description={t("title_6desc")}
        setVisibleModal={setVisibleTextToImage}
      /> */}
      <LokiTabs text title={t("howit_works")} fromHome />
      <div className="items-center flex justify-center">
        <VideoPlayer
        imgSrc="https://storage.googleapis.com/lokichat-profile/Chat%20with%20docs%20middle%20video.JPG"
          videoSrc={
            "https://player.vimeo.com/video/963491488?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
          }
        />
      </div>
      <WhyLoki />
      {/* <Learning
        title={t("title_learn4")}
        img="https://lokichatdev.blob.core.windows.net/devloki/loki-home/Multi-device.webp"
        description={t("title_4desc")}
        setVisibleModal={setVisibleModal}
      />
      <EnhancedLearning />
      <Learning
        ul
        title={t("title_learn5")}
        img="https://lokichatdev.blob.core.windows.net/devloki/pricing-page/pricing-page.webp"
        description={t("title_5desc")}
        setVisibleModal={setVisibleModal}
      /> */}
      <UseCase />
      <Illustration
        img={
          isDarkMode
            ? "https://storage.googleapis.com/lokichat-profile2/QR_Black.webp"
            : "https://storage.googleapis.com/lokichat-profile2/QR_White.webp"
        }
        className="flex justify-center max-w-[1200px] lmd:h-[630px] rounded-3xl overflow-hidden"
        height={630}
        width={1200}
      />
            {/* <div className='flex flex-col lmd:items-start items-center'>
        <a
          href={`ttps://play.google.com/store/apps/details?id=com.renesis.lokichat&hl=en&gl=US`}
          target="_blank"
          // rel="noopener noreferrer"
          className="block cursor-pointer p-2 bg-[#45B26B] rounded-md text-xs font-bold font-mont cursor-pointer font-mont absolute bottom-[219px] left-[20%] max-w-[200px]"
        >
          {t("Download Now")}
        </a>
        </div> */}
      <div className="container">
        <h2 className="step-2 leading-10 self-center text-center">
          {t("Frequent_question")}
        </h2>
        <div className=" mx-auto my-8">
          {mainquestionAnswer.map((item, index) => (
            <Questions
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </div>
      <Unleash />

      <Modal
        className="!p-0 !bg-none dark:!bg-none"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-none dark:!bg-none !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        video
      >
        <DiscoverModal onCancel={() => setVisibleModal(false)} />
      </Modal>

      <Modal
        className="!p-0 !bg-transparent dark:!bg-transparent"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-transparent dark:!bg-transparent !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleCollaborate}
        onClose={() => setVisibleCollaborate(false)}
        video
      >
        <CollaborativeDebateModal
          onCancel={() => setVisibleCollaborate(false)}
        />
      </Modal>
      <Modal
        className="!p-0 !bg-transparent dark:!bg-transparent"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-transparent dark:!bg-transparent !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleTextToImage}
        onClose={() => setVisibleTextToImage(false)}
        video
      >
        <TextToImageModal onCancel={() => setVisibleTextToImage(false)} />
      </Modal>
      <Modal
        classWrap="max-w-7xl"
        classButtonClose="hidden"
        visible={show}
        onClose={() => setShow(false)}
      >
        <FreeTrialModal />
      </Modal>
    </div>
  );
};

export default HomeModule;
